import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ToastService} from './toast.service';
import {environment} from 'src/environments/environment';
import {MonitoringService} from './monitoring.service';
import {Constants} from 'src/constants';
import {Messages} from 'src/internationalization/messages/messages';
import {LoginService} from 'src/app/resource/service/login.service';
import {ShouldRetryFn} from '../rxjs-utils';
import {AccessService} from 'src/app/resource/service/access.service';

@Injectable({
	providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
	private _constants = Constants.constant;
	private block = [
		environment.authorizerHost + 'userinformation',
		environment.scheduleHost +
		'(proforma/)([0-9]{1,9})(/proforma-vessel)(.{1})(validate=true)'
	];
	private shouldRetry: ShouldRetryFn = (error) => error.status === 401;

	constructor(
		private toastService: ToastService,
		monitoringService: MonitoringService,
		private loginService: LoginService,
		private accessService: AccessService
	) {
	}

	retryCount = 0;

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					switch (req.method) {
						case this._constants.http.POST:
						case this._constants.http.PATCH:
						case this._constants.http.DELETE:
							if (this.ignoreToastShowByUrl(event.url)) {
								break;
							}

							if (

								!this.checkUrl(event.url) && this.ignoreSuccessToastShowByUrl(event.url) &&
								event.status == 200 || event.status == 201 &&
								event.body &&
								event.body.message
							) {
								this.toastService.show(
									this._constants.messageType.success,
									Messages.getMessages().success_message
								);
							}

							if (
								!this.checkUrl(event.url) &&
								event.status == 200 &&
								!event.body
							) {
								this.toastService.show(
									this._constants.messageType.error,
									Messages.getMessages().request_without_response
								);
								throw new Error();
							}

							break;
					}

					// this.report(req, event);
				}

				return event;
			}),
			catchError((err: HttpErrorResponse) => {
				switch (err.status) {
					case 400:
						const currentMessage = err.error.message;
						let message = Messages.getMessages()[currentMessage];
						message
							? message
							: (message = Messages.getMessages()[err.error.message]);
						if (
							err.error.message !=
							'PRODUCT_WITH_SAME_CHARACTERISTICS_THAN_OTHERS_IN_DATABASE' &&
							err.error.message !=
							'This Proforma has Port Terminal with edited TEUS and TONS.' &&
							err.error.message != 'NO_VESSEL_IN_PROFORMA' &&
							err.error.message != 'PRODUCT_NAME_NOT_UNIQUE' &&
							err.error.message != 'GROUP_NAME_ALREADY_EXISTS'
						) {
							this.toastService.show(
								this._constants.messageType.error,
								message ? message : currentMessage || err.error.message
							);
						}
						break;
					case 401:
						return this.retryReq(req, next);
					case 403:
						this.toastService.show(
							this._constants.messageType.error,
							Messages.getMessages().unexpected_error
						);

						return this.retryReq(req, next);
					case 404:
						break;
					case 409:
						break;
					case 500:
						this.toastService.show(
							this._constants.messageType.error,
							Messages.getMessages().unexpected_error
						);
						break;
					case 504:
						this.toastService.show(
							this._constants.messageType.error,
							Messages.getMessages().request_time_out
						);
						break;
					default:
						switch (req.method) {
							case this._constants.http.POST:
							case this._constants.http.PATCH:
							case this._constants.http.DELETE:
								this.toastService.show(
									this._constants.messageType.error,
									err.error.message
								);
								break;
						}
						break;
				}

				// this.report(req, err);

				return throwError(err);
			})
		) as Observable<HttpEvent<any>>;
	}

	private async retryReq(req: HttpRequest<any>, next: HttpHandler) {
		if (this.retryCount > 3) {
			throw new Error('Retry limit reached.');
		}
		this.retryCount += 1;
		return await this.updateTokenAndRetry(req, next);
	}

	private updateTokenAndRetry(req, next) {
		return new Promise(
			async function(resolve, reject) {
				try {
					await this.accessService.fetchTokenSilently(true);
					this.accessService.trySilentToken().then(async (data) => {
						const updatedIdToken = 'Bearer ' + data.idToken.rawIdToken;
						const newReq = req.clone({
							setHeaders: {Authorization: updatedIdToken}
						});
						const retryData = await next.handle(newReq).toPromise();

						resolve(retryData);
					});
				} catch (e) {
					console.warn('updateTokenAndRetry failed');
					throw e;
				}
			}.bind(this)
		);
	}

	private checkUrl(url: string) {
		return this.block.find((u) => {
			return u == url || new RegExp(u).test(url);
		});
	}

	// under construction
	private report(
		req: HttpRequest<any>,
		err: HttpResponse<any> | HttpErrorResponse
	) {
		if (err.status != 200) {
			const requestreport = {
				method: req.method,
				url: req.urlWithParams,
				// tslint:disable-next-line: no-string-literal
				locale: this.getParamValue(req.headers['headers'], 'locale')
			};
			let responseReport = {};

			// Create resp
			if (err instanceof HttpResponse) {
				if (!err.body) {
					responseReport = {
						status: err.status,
						response: 'no response'
					};
				}
			} else {
				if (err && err.status && err.message) {
					responseReport = {
						status: err.status,
						message: err.message
					};
				}
			}

			// showing results
			if (responseReport == {}) {
				const error = {
					request: requestreport,
					response: responseReport
				};

				console.error('ERROR: '.concat(JSON.stringify(error)));
			} else {
				console.error('ERROR: '.concat(JSON.stringify(responseReport)));
			}
		}
	}

	private getParamValue(mapFilter, keyToFind) {
		for (const [key, value] of mapFilter) {
			if (key == keyToFind) {
				return value[0];
			}
		}
	}

	private ignoreToastShowByUrl(url: string): boolean {
		const ignoredUrls = [
			'schedule/ad-hoc',
			'agreement/reference',
			'commercial/agreements/corporategroup',
			'commercial/agreements/by-agreements',
			'costing/characteristic?strSearch',
			'costing/product/validate-product',
			'commercial/historic/calculate',
			'temporary-instances/cancel',
			'product/instance-bulk',
			'temporary-instances/cost-exception',
			'characteristic/characteristic-number',
			'manual-group/download',
		];

		const filteredUrls: Array<string> = ignoredUrls.filter((ignoredUrl) =>
			url.includes(ignoredUrl)
		);

		return filteredUrls.length > 0;
	}

	private ignoreSuccessToastShowByUrl(url: string): boolean {
		const ignoredUrls = [
			'historic/closed-won',
			'special-requirement/check-product-status',
			'temporary-instances/cost-instance',
			'duplicate-check',
			'postpone'
		];

		const filteredUrls: Array<string> = ignoredUrls.filter((ignoredUrl) =>
			url.includes(ignoredUrl)
		);

		return !(filteredUrls.length > 0);
	}
}