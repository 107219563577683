import {getLocation} from "../location";

export const eytVariables = {
	redirectUri: 'https://apiazewtanli001web.azurewebsites.net/',
	authorizerHost: 'https://apiazewtanli001auth.azurewebsites.net/v1/authorizer/',
	customerHost: 'https://apiazewtanli001customer.azurewebsites.net/v1/customer/',
	additionalFeesHost: '',
	commercial:
		'https://apiazewtanli001commercial.azurewebsites.net/v1/commercial/',
	costingHost: 'https://apiazewtanli001costing.azurewebsites.net/v1/costing/',
	costingModuleHost:
		'https://apiazewtanli001costing.azurewebsites.net/v1/costing/',
	historicHost: 'https://apiazewtanli001historic.azurewebsites.net/v1/historic/',
	generalRegisterHost:
		'https://apiazewtanli002generalregister.azurewebsites.net/v1/general-register/',
	vesselHost: 'https://apiazewtanli001vessel.azurewebsites.net/v1/vessel/',
	scheduleHost: 'https://apiazewtanli001schedule.azurewebsites.net/v1/schedule/',
	localityMapperHost:
		'https://apiazewtanli002localitymapper.azurewebsites.net/v1/locality-mapper/',
	productHost: 'https://apiazewtanli001product.azurewebsites.net/v1/',
	pricing: 'https://apiazewtanli001pricing.azurewebsites.net/v1/pricing/',
	scheduleMaerskHost: 'https://apiazewtanli001mskschedules.azurewebsites.net/v1/schedules/',
	azfUploadFilesToBlob:
		'https://faazewrg3tanl001upf.azurewebsites.net/',
	vesselHostV2: 'https://apiazewtanli001vessel.azurewebsites.net/v2/vessel/',
	proformaHost: 'https://apiazewtanli001prof.azurewebsites.net/v1/',
	fileReference: 'environment.ts',
	ambient: 'eyt' as const,
	version: '1.7.0',
	consumerkey: 'qKDWkxl7TOIBnxGbOaj9zMC0kIkY2Iqc',
	instrumentationKey: '30287add-fbe6-4f4e-a51a-c04a3b7f5f94',
	production: true,
	clientId: 'cdb5dd96-499a-4ab6-a709-7f47e99224c5',
	language: 'en',
	tenant: '05d75c05-fa1a-42e7-9cf1-eb416c396f2d'
};


//VWxDVTDvCoMG4QZShAQd4svboaTuwIPR
