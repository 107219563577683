import { Directive, Input, Output, EventEmitter, ElementRef, HostListener, SimpleChanges, OnDestroy } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
	selector: '[appOffsetHeight]'
})
export class OffsetHeightDirective implements OnDestroy {
	@Input() height: number;
	@Output() heightChange = new EventEmitter<number>();

	private _time: Subscription;
	private _count = 0;
	private _subscriptionList: Subscription[] = [];

	constructor(private element: ElementRef) { }

	ngAfterViewInit() {
		this.schedule();  // Inicializa a verificação da altura após a visualização
	}

	@HostListener('window:resize')  // Ouve o evento de resize da janela
	onResize() {
		this.schedule();
	}

	ngOnChanges(change: SimpleChanges) {
		this.schedule();  // Executa a lógica ao mudar as entradas
	}

	schedule() {
		if (this._time) {
			this._time.unsubscribe();  // Cancela a assinatura anterior
		}

		// Usando debounceTime para evitar chamadas excessivas
		this._time = timer(1).pipe(debounceTime(300)).subscribe(() => {  // Ajuste o debounce conforme necessário
			let height = this.element.nativeElement.offsetHeight;

			// Verifica se a altura mudou e se o contador ainda está dentro do limite
			if (this.height !== height && this._count < 20) {
				this._count++;

				// Atualiza a altura e emite o evento
				this.height = height;
				this.heightChange.emit(this.height);
			} else {
				this._count = 0;  // Reseta o contador se a altura não mudar
			}
		});

		// Gerencia as assinaturas para garantir que sejam canceladas corretamente
		this._subscriptionList = this.incrementSubscriptionList(this._subscriptionList, this._time);
	}

	ngOnDestroy() {
		// Cancela todas as assinaturas ao destruir o componente
		this.unsubscribeSubscriptionList(this._subscriptionList);
	}

	// Métodos auxiliares para gerenciar as assinaturas
	private incrementSubscriptionList(list: Subscription[], subscription: Subscription): Subscription[] {
		list.push(subscription);
		return list;
	}

	private unsubscribeSubscriptionList(list: Subscription[]) {
		list.forEach(subscription => subscription.unsubscribe());
	}
}
